import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import {
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  Grid,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Colors } from '../../../styles/Theme/ThemeStyles';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import { pickUpPointsData } from '../../../api/pickUpPoints';

type User = {
  given_name: string;
  family_name: string;
  email: string;
};

function Address({ updateStep, previousStep }: any) {
  const {
    cartService,
    shippingSelected,
    defaultShipSelected,
    cartItemData,
    cartAddressDetails,
    updateDeliveryInfo, // Ensure this is available from your context
    cartItemList,
    setCartItemList,
    billingAddress, // Import billingAddress from context
  } = useShoppingCart();

  const { user } = useAuth() as {
    user: User | undefined;
  };

  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [isDifferentBillingAddress, setIsDifferentBillingAddress] =
    useState<boolean>(false);
  const { handleSubmit } = useForm<any>();
  const [selectedStore, setSelectedStore] = useState<any>(null);

  const formSubmit = handleSubmit(async (data, e) => {
    e?.preventDefault();

    // Only proceed if we have a selected store for collection
    if (!shippingSelected && !defaultShipSelected && selectedStore) {
      try {
        setIsLoading(true);

        // Format the contact person object - use user details if available
        const contactPerson = {
          name: user?.given_name || '',
          surname: user?.family_name || '',
          phone: user?.email ? user?.email.split('@')[0] : '', // Fallback to a part of email if no phone
        };

        // Create a billing address object from the selected store
        const billingAddress = {
          name: user?.given_name || '',
          surname: user?.family_name || '',
          email: user?.email || '',
          address: selectedStore.address,
          apartment: '',
          suburb: selectedStore.suburb,
          city: selectedStore.city,
          province: selectedStore.province,
          country: selectedStore.country,
          postalCode: selectedStore.postalCode,
        };

        // Preserve existing notes from the previous step
        const existingNotes = cartItemData?.order_notes || '';

        // Update cart items preserving any custom notes
        if (cartItemList && cartItemList.length > 0) {
          setCartItemList(
            cartItemList.map((item: any) => {
              return {
                ...item,
                altShippingAddress: billingAddress,
                pickupLocation: selectedStore.title,
                orderNotes: existingNotes // Use existing notes only
              };
            })
          );
        }

        // Update delivery information with existing notes
        await updateDeliveryInfo(
          billingAddress,
          'COLLECTION',
          cartItemData?.customer_order_num || '',
          existingNotes, // Use existing notes only
          contactPerson
        );

        setIsLoading(false);
        updateStep(); // Move to the next step
      } catch (error) {
        console.error('Failed to update delivery info:', error);
        setIsLoading(false);
      }
    } else if (shippingSelected || defaultShipSelected) {
      // If using shipping, just proceed to the next step
      // The delivery info was already set in the UserInformation component
      updateStep();
    }
  });

  useEffect(() => {
    if (!shippingSelected && !defaultShipSelected) {
      setIsDifferentBillingAddress(true);
    }
  }, [shippingSelected, defaultShipSelected]);

  // Helper function to determine which address to display
  const getDisplayAddress = () => {
    const deliveryType = cartItemData?.orders_shipping_address?.delivery_type;

    // For regular delivery, show the billing address
    if (deliveryType === 'DELIVERY' || defaultShipSelected) {
      return {
        contactNumber: cartItemData?.contact_number || cartAddressDetails?.contact_number,
        addressLine: billingAddress?.address || cartAddressDetails?.physical1
      };
    }
    // For third-party delivery, show the shipping address
    else if (deliveryType === 'THIRD_PARTY_DELIVERY' || shippingSelected) {
      return {
        contactNumber: cartItemData?.contact_number || cartAddressDetails?.contact_number,
        addressLine: cartItemData?.orders_shipping_address?.address_line_1 || cartAddressDetails?.physical1
      };
    }

    // Default fallback
    return {
      contactNumber: cartItemData?.contact_number || cartAddressDetails?.contact_number,
      addressLine: cartItemData?.orders_shipping_address?.address_line_1 || cartAddressDetails?.physical1
    };
  };

  const addressDisplay = getDisplayAddress();

  return (
    <Box>
      {shippingSelected || defaultShipSelected ? (
        <form noValidate onSubmit={formSubmit}>
          <Stack
            sx={{
              border: `${Colors.info} 1px solid`,
              borderTop: 'none',
              mb: 2,
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  py: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    p: 2,
                  }}
                >
                  <Box sx={{ flex: 2 }}>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '27px',
                      }}
                    >
                      Contact: {addressDisplay.contactNumber}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                {(shippingSelected || defaultShipSelected) && (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        p: 2,
                      }}
                    >
                      <Box sx={{ flex: 2 }}>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: '400',
                            lineHeight: '27px',
                          }}
                        >
                          Ship to:{' '}
                          {addressDisplay.addressLine}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                  </>
                )}
              </>
            )}
          </Stack>

          <Divider sx={{ mt: 2 }} />
          <Grid container mt={2} justifyContent="space-between">
            <Box
              onClick={previousStep}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '0px',
                cursor: 'pointer',
              }}
            >
              <Typography>Back</Typography>
            </Box>
            <Button
              variant="contained"
              type="button"
              onClick={() => updateStep()}
              disableElevation
              sx={{
                borderRadius: '0px',
              }}
            >
              Next
            </Button>
          </Grid>
        </form>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                py: '10px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                }}
              >
                Select collection
              </Typography>
            </Box>
            {isLoading ? (
              <Box
                sx={{
                  py: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <RadioGroup
                onChange={(e) => {
                  let selectedStoreFound = null;
                  for (const data of pickUpPointsData) {
                    const store = data.stores.find(
                      (store) => store.title === e.target.value
                    );
                    if (store) {
                      selectedStoreFound = store;
                      break;
                    }
                  }
                  setSelectedStore(selectedStoreFound);
                }}
              >
                {pickUpPointsData.map((data) => (
                  <Accordion
                    key={data.title}
                    elevation={0}
                    expanded={expanded === data.title}
                    onChange={handleChange(data.title)}
                  >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowDown />}
                      aria-controls={`${data.title}-content`}
                      id={`${data.title}-header`}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '16px',
                        }}
                      >
                        {data.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        {data.stores.map((store) => (
                          <Box
                            key={store.title}
                            mb={2}
                            sx={{
                              border: '1px solid #F5F5F5',
                              p: '10px',
                              backgroundColor: selectedStore?.title === store.title
                                ? '#f5f5f5'
                                : 'transparent',
                            }}
                          >
                            <FormControlLabel
                              value={store.title}
                              control={<Radio />}
                              label={
                                <>
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      fontSize: '14px',
                                    }}
                                  >
                                    {store.title}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontWeight: '400',
                                      fontSize: '12px',
                                    }}
                                  >
                                    {`${store.address}, ${store.suburb}, ${store.city}, ${store.province}, ${store.postalCode}, ${store.country}`}
                                  </Typography>
                                  {store.phone && (
                                    <Typography
                                      sx={{
                                        fontWeight: '400',
                                        fontSize: '12px',
                                      }}
                                    >
                                      Phone: {store.phone}
                                    </Typography>
                                  )}
                                </>
                              }
                            />
                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </RadioGroup>
            )}

            <Divider sx={{ mt: 2 }} />
            <Grid container mt={2} justifyContent="space-between">
              <Box
                onClick={previousStep}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '0px',
                  cursor: 'pointer',
                }}
              >
                <Typography>Back</Typography>
              </Box>
              <Button
                variant="contained"
                type="submit"
                onClick={() => formSubmit()}
                disableElevation
                sx={{
                  borderRadius: '0px',
                }}
                disabled={!selectedStore}
              >
                Next
              </Button>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Address;