import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import Cart from '../components/shared/shoppingCart/Cart';
import CartService from '../service/CartService';
import { useQuery, useQueryClient } from 'react-query';
import { useAuth } from './AuthContext';
import { discount, getStockForItem } from '../api/apiCart';

// Define type for stock adjustment result
type StockValidationResult = {
  stockChanged: boolean;
  adjustedItems: Array<{
    name: string;
    oldQuantity: number;
    newQuantity: number;
    status?: 'adjusted' | 'removed';
  }>;
};

type ShoppingCartContextType = {
  // Original cart properties
  setFormSteps: any;
  formSteps: number;
  setShippingSelected: any;
  shippingSelected: any;
  setDefaultShipSelected: any;
  defaultShipSelected: any;
  openCart: () => void;
  closeCart: () => void;
  setCartItemList: any;
  addToCart: (item: object) => void;
  removeFromCart: (item: object) => void;
  increaseQuantity: (item: object) => void;
  decreaseQuantity: (item: object) => void;
  addShippingPrice: (price: number) => void;
  processPaymentWithAccount: (cartId: string) => Promise<any>;
  processPaymentWithPayfast: (cartId: string) => Promise<any>;
  checkPaymentStatus: (cartId: string) => Promise<any>;
  clearCart: () => void;
  cartItemList: Array<object>;
  itemTotal: number;
  total: number;
  vatAmount: number;
  shippingPrice: number;
  cartService: CartService;
  setStockLink: (stock_link: string) => void;
  updateQuantity: (cartId: string, newQuantity: number) => void;
  discountAmount: any;
  discountAmount_total: number;
  discountAmount_type: string;
  loadingItemId: string | null;
  loadingDeleteItemId: string | null;
  billingAddress: any;
  cartId: string;
  cartItemData: any;
  cartAddressDetails: any;
  validateCartStock: () => Promise<StockValidationResult | null>;
  updateDeliveryInfo: (
    billingAddress: any,
    deliveryType: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY',
    customerOrderNum?: string,
    deliveryNotes?: string,
    contactPerson?: {
      name: string;
      surname: string;
      phone: string;
    },
    paymentType?: '30E' | 'PIA'
  ) => void;
  subTotal: number;

  // New cuttings cart properties
  cuttingsCartItemList: Array<object>;
  addToCuttingsCart: (item: object) => void;
  removeFromCuttingsCart: (item: object) => void;
  increaseCuttingQuantity: (item: object) => void;
  decreaseCuttingQuantity: (item: object) => void;
  clearCuttingsCart: () => void;
  updateCuttingQuantity: (itemId: string, newQuantity: number) => void;
  activeCart: 'regular' | 'cuttings';
  switchToCart: (cartType: 'regular' | 'cuttings') => void;
  submitCuttingsOrder: () => Promise<{success: boolean, message: string}>;
  isCuttingsOrderProcessing: boolean;
  loadingCuttingItemId: string | null;
};

type ShoppingCartProviderProps = {
  children: ReactNode;
};

const ShoppingCartContext = createContext({} as ShoppingCartContextType);

export const ShoppingCartProvider = ({
                                       children,
                                     }: ShoppingCartProviderProps) => {
  // Original cart state
  const [formSteps, setFormSteps] = useState<any>();
  const [loadingItemId, setLoadingItemId] = useState<string | null>(null);
  const [loadingDeleteItemId, setLoadingDeleteItemId] = useState<string | null>(null);
  const [billingAddress, setBillingAddress] = useState<any>(null);
  const [cartId, setCartId] = useState<string>('');
  const [cartAddressDetails, setCartAddressDetails] = useState<any>({});
  const [cartItemList, setCartItemList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [vatAmount, setVatAmount] = useState<number>(0);
  const [shippingPrice, setShippingPrice] = useState<number>(0);
  const [shippingSelected, setShippingSelected] = useState<boolean>();
  const [defaultShipSelected, setDefaultShipSelected] = useState<boolean>();
  const [stockLink, setStockLink] = useState<string>('');
  const [isValidatingStock, setIsValidatingStock] = useState<boolean>(false);

  // New state for cuttings cart
  const [cuttingsCartItemList, setCuttingsCartItemList] = useState<any[]>([]);
  const [activeCart, setActiveCart] = useState<'regular' | 'cuttings'>('regular');
  const [isCuttingsOrderProcessing, setIsCuttingsOrderProcessing] = useState<boolean>(false);
  const [loadingCuttingItemId, setLoadingCuttingItemId] = useState<string | null>(null);

  const { isLogin, setIsAccountOnHold } = useAuth();

  const authToken = localStorage.getItem('authToken') || '';
  const cartService = useMemo(
    () => new CartService(authToken),
    [authToken]
  );

  const queryClient = useQueryClient();

  // Load cuttings cart from localStorage on initial load
  useEffect(() => {
    const savedCuttingsCart = localStorage.getItem('cuttingsCartItems');
    if (savedCuttingsCart) {
      try {
        setCuttingsCartItemList(JSON.parse(savedCuttingsCart));
      } catch (e) {
        console.error('Error loading saved cuttings cart:', e);
      }
    }
  }, []);

  // Save cuttings cart to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('cuttingsCartItems', JSON.stringify(cuttingsCartItemList));
  }, [cuttingsCartItemList]);

  const { data: cartItemData } = useQuery(
    ['cart'],
    () => cartService.checkAndRetrieveCart(),
    {
      initialData: [],
      enabled: !!authToken && !!isLogin,
      onSuccess: (data) => {
        if (data?.on_hold === 'true') {
          setIsAccountOnHold(true);
        }
        setCartId(data?.id);

        // Check if this is a new cart and if the saved shipping address was for collection
        const isNewCart = !data?.items || data.items.length === 0;
        const savedAddressIsCollection =
          data?.context?.saved_shipping_address?.delivery_type === "COLLECTION";

        // STEP 1: Always extract the billing address first
        if (data?.billing_address) {
          const billingAddr = data.billing_address;

          // Format comprehensive billing address object
          const extractedBillingAddress = {
            email: data?.customer_email || '',
            name: billingAddr?.contact_person?.name || '',
            surname: billingAddr?.contact_person?.surname || '',
            address: billingAddr?.address_line_1 || '',
            apartment: billingAddr?.address_line_2 || '',
            suburb: billingAddr?.address_line_3 || '',
            city: billingAddr?.address_line_4 || '',
            province: (billingAddr?.address_line_5 || '').split(',')[0]?.trim() || '',
            country: (billingAddr?.address_line_5 || '').split(',')[1]?.trim() || 'South Africa',
            postalCode: billingAddr?.address_zip || '',
            contact_number: data?.contact_number || '',
          };

          // Set billing address
          setBillingAddress(extractedBillingAddress);

          // Default cartAddressDetails to billing address
          const formattedBillingAddress = {
            physical1: billingAddr.address_line_1 || '',
            physical2: billingAddr.address_line_2 || '',
            physical3: billingAddr.address_line_3 || '',
            physical4: billingAddr.address_line_4 || '',
            physical5: billingAddr.address_line_5 || '',
            physicalPC: billingAddr.address_zip || '',
            contact_number: data.contact_number || '',
            contact_person: billingAddr.contact_person || {},
          };

          setCartAddressDetails(formattedBillingAddress);
        }

        // STEP 2: Check if there's a shipping address and what type it is
        if (data?.orders_shipping_address) {
          const deliveryType = data.orders_shipping_address.delivery_type;
          const shippingAddr = data.orders_shipping_address;

          // COLLECTION: Use collection details from context if available
          if (deliveryType === "COLLECTION") {
            if (data?.context?.delivery_address) {
              const collectionAddress = {
                physical1: data.context.delivery_address.address_line_1 || 'Pickup location to be selected',
                physical2: data.context.delivery_address.address_line_2 || '',
                physical3: data.context.delivery_address.address_line_3 || '',
                physical4: data.context.delivery_address.address_line_4 || '',
                physical5: data.context.delivery_address.address_line_5 || '',
                physicalPC: data.context.delivery_address.address_zip || '0000',
                contact_number: data.contact_number || '',
                contact_person: data.context.contact_person || {},
                delivery_type: "COLLECTION"
              };

              // Update cartAddressDetails with collection info
              setCartAddressDetails(collectionAddress);
            }
          }
          // THIRD_PARTY_DELIVERY: Store in cartAddressDetails but don't override billing
          else if (deliveryType === "THIRD_PARTY_DELIVERY") {
            const thirdPartyAddress = {
              physical1: shippingAddr.address_line_1 || '',
              physical2: shippingAddr.address_line_2 || '',
              physical3: shippingAddr.address_line_3 || '',
              physical4: shippingAddr.address_line_4 || '',
              physical5: shippingAddr.address_line_5 || '',
              physicalPC: shippingAddr.address_zip || '',
              contact_number: data.contact_number || '',
              contact_person: shippingAddr.contact_person || {},
              delivery_type: "THIRD_PARTY_DELIVERY"
            };

            // Update cartAddressDetails with third-party info
            setCartAddressDetails(thirdPartyAddress);
          }
        }
      },
    }
  );

  const { data: discountAmount } = useQuery(
    ['discount', stockLink],
    () => discount(authToken, Number(stockLink)),
    {
      enabled: !!authToken && !!stockLink,
    }
  );

  const [discountAmountTotal, setDiscountAmountTotal] = useState<number>(0);
  const [discountAmountType, setDiscountAmountType] = useState<string>('');

  useEffect(() => {
    if (discountAmount) {
      setDiscountAmountTotal(discountAmount?.discount_total || 0);
      setDiscountAmountType(discountAmount?.discount_type || '');
    }
  }, [discountAmount]);

  // Original cart functions
  const openCart = () => {
    setIsOpen(true);
  };

  const closeCart = () => {
    setIsOpen(false);
  };

  const validateCartStock = async (): Promise<StockValidationResult | null> => {
    if (!cartItemList || cartItemList.length === 0 || !cartId) {
      return null;
    }

    try {
      const adjustedItems = [];
      let stockChanged = false;

      // Loop through cart items and check stock for each
      for (const item of cartItemList) {
        // Get fresh stock data
        const stockResponse = await cartService.checkItemStock(item.sku);

        if (stockResponse && stockResponse.success) {
          // Get the stock value from the response
          const availableStock = stockResponse.stock_on_hand || 0;
          const currentItemQty = item.quantity || 0;

          console.log(`Stock validation for ${item.name}:`, {
            currentQty: currentItemQty,
            availableStock: availableStock
          });

          // Compare cart quantity directly with available stock
          if (currentItemQty > availableStock) {
            stockChanged = true;

            if (availableStock <= 0) {
              adjustedItems.push({
                name: item.name,
                oldQuantity: currentItemQty,
                newQuantity: 0,
                status: 'removed' as 'removed'
              });

              await removeFromCart(item);
            } else {
              adjustedItems.push({
                name: item.name,
                oldQuantity: currentItemQty,
                newQuantity: availableStock,
                status: 'adjusted' as 'adjusted'
              });

              await updateQuantity(item.id, availableStock);
            }
          }
        }
      }

      if (stockChanged) {
        queryClient.invalidateQueries('cart');
      }

      return { stockChanged, adjustedItems };
    } catch (error) {
      console.error('Error validating cart stock:', error);
      return null;
    }
  };

  const addToCart = async (cartItem: any) => {
    console.log("ADD TO CART - Starting with item:", cartItem.sku, "Stock before:", cartItem.stock);
    setLoadingItemId(cartItem.id);
    try {
      // If item exists in cart, calculate new quantity
      const existingCartItem = cartItemList.find(
        (item: any) => item.sku === cartItem.sku
      );

      const quantity = existingCartItem
        ? Math.min(existingCartItem.quantity + cartItem.quantity, cartItem.stock)
        : cartItem.quantity;

      console.log("ADD TO CART - Calculated quantity:", quantity);

      // Use the new endpoint to set item quantity
      const response = await cartService.setItemAmount(
        cartItemData.id,
        cartItem.sku,
        quantity
      );

      console.log("ADD TO CART - API Response:", response);

      // Refresh cart data
      queryClient.invalidateQueries('cart');

      // Switch to regular cart and open it
      setActiveCart('regular');
      setIsOpen(true);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const removeFromCart = async (cartItem: any) => {
    setLoadingDeleteItemId(cartItem.id);
    try {
      // Use the SKU instead of ID
      await cartService.deleteItemFromCart(cartItemData.id, cartItem.sku);

      // Immediately update the local state to reflect the removal
      setCartItemList((prevItems: any[]) =>
        prevItems.filter((item: any) => item.id !== cartItem.id)
      );

      // Also refresh cart data from the server
      await queryClient.refetchQueries('cart');
    } catch (error) {
      console.error('Error removing item from cart:', error);
    } finally {
      setLoadingDeleteItemId(null);
    }
  };

  const clearCart = async () => {
    try {
      await cartService.clearCartContents(cartItemData.id);

      // Refresh cart data
      queryClient.invalidateQueries('cart');
      setCartItemList([]);
    } catch (error) {
      console.error('Error clearing cart:', error);
    }
  };

  const updateQuantity = async (cartItemId: string, newQuantity: number) => {
    setLoadingItemId(cartItemId);
    try {
      // Find the item in cartItemList to get its SKU
      const cartItem = cartItemList.find((item: any) => item.id === cartItemId);
      if (!cartItem) {
        console.error('Item not found in cart');
        return;
      }

      // Use the SKU, not the ID
      await cartService.setItemAmount(
        cartItemData.id,
        cartItem.sku, // Use SKU here, not cartItemId
        newQuantity
      );

      // Refresh cart data
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating quantity:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const increaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    try {
      // Send the request to increase by 1
      const response = await cartService.editItemAmount(
        cartItemData.id,
        cartItem.sku,
        1
      );



      // If the server response is successful, immediately update all relevant states
      if (response && response.success) {
        // Find the updated item in the cart response
        const updatedItem = response.cart?.items?.find((i: any) => i.sku === cartItem.sku);

        if (updatedItem) {
          // Immediately update this item in the cart list with all relevant properties
          setCartItemList((prevItems: any[]) =>
            prevItems.map((item: any) =>
              item.sku === cartItem.sku
                ? {
                  ...item,
                  quantity: updatedItem.quantity,
                  unit_price_excl: updatedItem.unit_price_excl,
                  unit_price_incl: updatedItem.unit_price_incl,
                  item_total_excl: updatedItem.item_total_excl,
                  item_total_incl: updatedItem.item_total_incl,
                  stock: response.stock_on_hand !== undefined ? response.stock_on_hand : item.stock,
                }
                : item
            )
          );

          // Also update the cart totals immediately from the response
          if (response.cart) {
            setSubTotal(response.cart.subtotal || 0);
            setVatAmount(response.cart.tax_total || 0);
            setTotal(response.cart.total || 0);
          }
        }
      } else {
        console.error("Failed to increase quantity:", response?.msg);

        // Even on failure, update local stock information if provided
        if (response && response.stock_on_hand !== undefined) {
          setCartItemList((prevItems: any[]) =>
            prevItems.map((item: any) =>
              item.sku === cartItem.sku
                ? {
                  ...item,
                  stock: response.stock_on_hand,
                  stock_on_hand: response.stock_on_hand,
                  total_available: response.total_available
                }
                : item
            )
          );
        }
      }

      // Still do a query invalidation to ensure backend sync
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error increasing quantity:', error);
      queryClient.invalidateQueries('cart');
    } finally {
      setLoadingItemId(null);
    }
  };

  const decreaseQuantity = async (cartItem: any) => {
    setLoadingItemId(cartItem.id);
    try {
      if (cartItem.quantity > 1) {
        // Use the new endpoint to decrease quantity by 1
        await cartService.editItemAmount(
          cartItemData.id,
          cartItem.sku, // Should be the SKU
          -1
        );

        // Refresh cart data
        queryClient.invalidateQueries('cart');
      } else {
        // If quantity is 1, remove the item
        removeFromCart(cartItem);
      }
    } catch (error) {
      console.error('Error decreasing quantity:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  const addShippingPrice = (price: number) => {
    setShippingPrice(price);
  };

  const updateDeliveryInfo = async (
    billingAddress: any,
    deliveryType: 'COLLECTION' | 'DELIVERY' | 'THIRD_PARTY_DELIVERY',
    customerOrderNum?: string,
    deliveryNotes?: string,
    contactPerson?: {
      name: string;
      surname: string;
      phone: string;
    },
    paymentType: '30E' | 'PIA' = 'PIA'
  ) => {
    try {
      // Format the address from the frontend format to the API format
      const shippingAddress = {
        address_line_1: billingAddress.address || '',
        address_line_2: billingAddress.apartment || '',
        address_line_3: billingAddress.suburb || '',
        address_line_4: billingAddress.city || '',
        address_line_5: `${billingAddress.province || ''}, ${billingAddress.country || ''}`,
        address_zip: billingAddress.postalCode || '',
      };

      const deliveryInfo = {
        shipping_address: shippingAddress,
        delivery_type: deliveryType,
        delivery_note: deliveryNotes || '',
        customer_order_num: customerOrderNum || '',
        contact_person: contactPerson || {
          name: billingAddress.name || '',
          surname: billingAddress.surname || '',
          phone: billingAddress.contact_number || '',
        },
        payment_type: paymentType,
      };

      await cartService.updateDeliveryInfo(cartItemData.id, deliveryInfo);
      setBillingAddress(billingAddress);
      queryClient.invalidateQueries('cart');
    } catch (error) {
      console.error('Error updating delivery information:', error);
    }
  };

  const processPaymentWithAccount = async (cartId: string) => {
    try {
      return await cartService.finalizeUserCartWithAccount(cartId);
    } catch (error) {
      console.error('Error processing account payment:', error);
      throw error;
    }
  };

  const processPaymentWithPayfast = async (cartId: string) => {
    try {
      return await cartService.finalizeUserCartWithPayfast(cartId);
    } catch (error) {
      console.error('Error processing PayFast payment:', error);
      throw error;
    }
  };

  const checkPaymentStatus = async (cartId: string) => {
    try {
      return await cartService.getCartPaymentStatus(cartId);
    } catch (error) {
      console.error('Error checking payment status:', error);
      throw error;
    }
  };

  const addToCuttingsCart = (cuttingItem: any) => {
    // Calculate total items currently in cutting cart
    const totalItems = cuttingsCartItemList.reduce((sum, item) => sum + item.quantity, 0);

    // Check if item already exists in cart
    const existingItem = cuttingsCartItemList.find((item: any) => item.sku === cuttingItem.sku);

    // If adding this item would exceed the 20 item limit, show alert and stop
    if (!existingItem && totalItems >= 20) {
      alert('Cuttings cart is limited to 20 total cuts per order.');
      return;
    }

    // If existing item would exceed 3 cuts, adjust the quantity
    const requestedQuantity = existingItem
      ? Math.min(3, existingItem.quantity + cuttingItem.quantity)
      : Math.min(3, cuttingItem.quantity);

    // If adding more to existing would exceed 20 total, adjust
    if (existingItem && totalItems - existingItem.quantity + requestedQuantity > 20) {
      alert('Cuttings cart is limited to 20 total cuts per order.');
      return;
    }

    setCuttingsCartItemList((prevItems: any[]) => {
      if (existingItem) {
        // Update quantity for existing item (max 3)
        return prevItems.map((item: any) =>
          item.sku === cuttingItem.sku
            ? { ...item, quantity: requestedQuantity }
            : item
        );
      } else {
        // Add new item with a clientId to identify it locally
        // Ensure quantity doesn't exceed 3
        return [...prevItems, {
          ...cuttingItem,
          id: cuttingItem.id || `cutting-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          quantity: Math.min(3, cuttingItem.quantity)
        }];
      }
    });

    // Open the cart drawer and switch to cuttings view
    setActiveCart('cuttings');
    setIsOpen(true);
  };

  const removeFromCuttingsCart = (cuttingItem: any) => {
    setLoadingCuttingItemId(cuttingItem.id);
    try {
      setCuttingsCartItemList((prevItems: any[]) =>
        prevItems.filter((item: any) => item.id !== cuttingItem.id)
      );
    } finally {
      setLoadingCuttingItemId(null);
    }
  };

  const clearCuttingsCart = () => {
    setCuttingsCartItemList([]);
  };

  const increaseCuttingQuantity = (cuttingItem: any) => {
    setLoadingCuttingItemId(cuttingItem.id);
    try {
      setCuttingsCartItemList((prevItems: any[]) =>
        prevItems.map((item: any) =>
          item.id === cuttingItem.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } finally {
      setLoadingCuttingItemId(null);
    }
  };

  const decreaseCuttingQuantity = (cuttingItem: any) => {
    setLoadingCuttingItemId(cuttingItem.id);
    try {
      if (cuttingItem.quantity > 1) {
        setCuttingsCartItemList((prevItems: any[]) =>
          prevItems.map((item: any) =>
            item.id === cuttingItem.id
              ? { ...item, quantity: item.quantity - 1 }
              : item
          )
        );
      } else {
        // If quantity is 1, remove the item
        removeFromCuttingsCart(cuttingItem);
      }
    } finally {
      setLoadingCuttingItemId(null);
    }
  };

  const updateCuttingQuantity = (itemId: string, newQuantity: number) => {
    setLoadingCuttingItemId(itemId);
    try {
      setCuttingsCartItemList((prevItems: any[]) =>
        prevItems.map((item: any) =>
          item.id === itemId
            ? { ...item, quantity: newQuantity }
            : item
        )
      );
    } finally {
      setLoadingCuttingItemId(null);
    }
  };

  const switchToCart = (cartType: 'regular' | 'cuttings') => {
    setActiveCart(cartType);
  };

  const submitCuttingsOrder = async () => {
    // This will be replaced with the actual API call later
    setIsCuttingsOrderProcessing(true);

    try {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Clear cuttings cart after successful submission
      clearCuttingsCart();

      // Return success response (will be replaced with actual API response)
      return { success: true, message: 'Your cutting order has been submitted successfully.' };
    } catch (error) {
      console.error('Error submitting cuttings order:', error);
      return { success: false, message: 'There was an error processing your cutting order.' };
    } finally {
      setIsCuttingsOrderProcessing(false);
    }
  };

  useEffect(() => {
    if (
      cartItemData &&
      Array.isArray(cartItemData.items) &&
      cartItemData.items.length > 0
    ) {
      // Get the latest stock information before setting cart items
      const fetchStockInfo = async () => {
        // Create a copy of the items to modify
        const itemsWithStock = cartItemData.items.map((item: any) => ({
          ...item,
          // Use the stock_on_hand property if it exists
          stock: item.stock_on_hand,
          on_hand: item.stock_on_hand
        }));

        // Set cartItemList with the enhanced items
        setCartItemList(itemsWithStock);
      };

      fetchStockInfo();
    } else {
      setCartItemList([]);
    }
  }, [cartItemData]);

  useEffect(() => {
    if (cartItemData) {
      // Swap subtotal and VAT to match UI expectations
      setSubTotal(cartItemData.subtotal || 0);
      setVatAmount(cartItemData.tax_total || 0);
      setTotal(cartItemData.total || 0);
    }
  }, [cartItemData]);

  return (
    <ShoppingCartContext.Provider
      value={{
        // Original cart values
        setFormSteps,
        setShippingSelected,
        setCartItemList,
        openCart,
        closeCart,
        addToCart,
        removeFromCart,
        increaseQuantity,
        decreaseQuantity,
        addShippingPrice,
        updateDeliveryInfo,
        clearCart,
        processPaymentWithAccount,
        processPaymentWithPayfast,
        checkPaymentStatus,
        formSteps,
        shippingSelected,
        cartItemList,
        itemTotal,
        total,
        vatAmount,
        shippingPrice,
        cartService,
        setDefaultShipSelected,
        defaultShipSelected,
        updateQuantity,
        setStockLink,
        discountAmount,
        discountAmount_total: discountAmountTotal,
        discountAmount_type: discountAmountType,
        loadingItemId,
        loadingDeleteItemId,
        billingAddress,
        cartItemData,
        cartAddressDetails,
        cartId,
        subTotal,
        validateCartStock,

        // New cuttings cart values
        cuttingsCartItemList,
        addToCuttingsCart,
        removeFromCuttingsCart,
        increaseCuttingQuantity,
        decreaseCuttingQuantity,
        clearCuttingsCart,
        updateCuttingQuantity,
        activeCart,
        switchToCart,
        submitCuttingsOrder,
        isCuttingsOrderProcessing,
        loadingCuttingItemId,
      }}
    >
      {children}
      <Cart isOpen={isOpen} />
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = () => {
  return useContext(ShoppingCartContext);
};