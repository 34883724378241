import { useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useParams, useLocation, useNavigationType } from 'react-router-dom';
import {
  apiGetApplicationNamesByItemGroup,
  apiGetCategoryMainPageData,
} from '../../../api/apiCategories';
import PageLayout from '../../../components/shared/layout/PageLayout';
import CarouselBanner from '../../../components/shared/banner/CarouselBanner';
import TextHeader from '../../../components/shared/textHeader/TextHeader';
import NewArrivalsGrid from '../../../components/shared/grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../../../components/shared/loadingScreen/LoadingScreen';
import FeaturedGallery from '../../../components/shared/gallery/FeaturedGallery';
import MissingPage from '../errorMessages/MissingPage';
import ItemGrid from '../grid/ItemGrid';
import HomewareCategoryGrid from '../grid/HomeWareCategoryGrid';
import { captureAnalytics } from '../../../utilities/Analytics';

// Type definitions
interface ApplicationCard {
  application_name: string;
  [key: string]: any;
}

function CategoryTemplate() {
  const { item_group } = useParams();
  const [filteredApplicationCards, setFilteredApplicationCards] = useState<ApplicationCard[]>([]);
  const location = useLocation();
  const navigationType = useNavigationType();

  // Create scroll position management
  const scrollPositionKey = `category_scroll_${item_group}`;
  const scrollRestored = useRef(false);

  const itemGroupData = {
    category: { item_group: item_group },
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: categoryData,
  } = useQuery(
    [`main-category-${item_group}`],
    () => apiGetCategoryMainPageData(itemGroupData),
    {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );

  const { data: applicationNames } = useQuery(
    [`application-names-${item_group}`],
    () => apiGetApplicationNamesByItemGroup(item_group || ''),
    {
      staleTime: 300000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  );

  // Filter application cards based on available application names
  useEffect(() => {
    if (isSuccess && categoryData && applicationNames?.applications) {
      const filtered = categoryData.section_application_cards.filter((card: ApplicationCard) =>
        applicationNames.applications.includes(card.application_name)
      );
      setFilteredApplicationCards(filtered);
    }
  }, [isSuccess, categoryData, applicationNames]);

  // Only scroll to top on new navigations, not when returning
  useEffect(() => {
    if (navigationType !== 'POP') {
      // New navigation, scroll to top
      window.scrollTo(0, 0);
      sessionStorage.removeItem(scrollPositionKey);
    } else {
      // Coming back, try to restore position
      const savedPosition = sessionStorage.getItem(scrollPositionKey);
      if (savedPosition) {
        // Try to restore immediately
        window.scrollTo(0, parseInt(savedPosition, 10));

        // Also try after a short delay for when content loads
        const timeoutId = setTimeout(() => {
          window.scrollTo(0, parseInt(savedPosition, 10));

          // And once more after images should have loaded
          const finalTimeout = setTimeout(() => {
            window.scrollTo(0, parseInt(savedPosition, 10));
          }, 300);

          return () => clearTimeout(finalTimeout);
        }, 100);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [navigationType, scrollPositionKey]);

  // Save scroll position when leaving the page
  useEffect(() => {
    const handleScroll = () => {
      // Continuously update scroll position in session storage
      sessionStorage.setItem(scrollPositionKey, window.scrollY.toString());
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPositionKey]);

  // Handle window resize events which can change layout and affect scroll position
  useEffect(() => {
    const handleResize = () => {
      const savedPosition = sessionStorage.getItem(scrollPositionKey);
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition, 10));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [scrollPositionKey]);

  // Analytics tracking
  useEffect(() => {
    if (isSuccess && categoryData) {
      const title = categoryData.page_title;
      captureAnalytics('pageview', location.pathname, title);
    }
  }, [location.pathname, item_group, isSuccess, categoryData]);

  return (
    <PageLayout>
      {isLoading && <LoadingScreen />}
      {isError && <MissingPage />}
      {isSuccess && (
        <>
          <CarouselBanner
            heroImages={categoryData?.section_trade_hero}
            heroLink={categoryData?.section_trade_hero_link}
            heroVideo={categoryData?.section_trade_hero_video ? {
              video_url: categoryData.section_trade_hero_video.video_url,
              thumbnail_url: categoryData.section_trade_hero_video.thumbnail_url
            } : undefined}
            videoLink={categoryData?.section_trade_video_link}
            promoImages={categoryData?.section_trade_promo}
            promoLink={categoryData?.section_trade_promo_link}
            displayOption={categoryData?.trade_display_option}
          />

          <TextHeader
            heading={categoryData?.page_title}
            content={categoryData?.description}
            isCentered={false}
            width='70%'
            marginTop={10}
            marginBottom={5}
          />

          {item_group === '000' ? (
            <HomewareCategoryGrid
              data={categoryData?.section_homeware_landing_categories}
              spacingValue={4}
              marginTop={5}
              marginBottom={5}
            />
          ) : (
            <ItemGrid
              data={filteredApplicationCards}
              hasTitles={false}
              hasButtons={false}
              hasBackgroundText={true}
              spacingValue={4}
              hasCollectionLinks={false}
              marginTop={5}
              marginBottom={5}
            />
          )}

          <FeaturedGallery
            images={categoryData?.section_featured_collection}
            hasVideo={false}
            invert={false}
            marginTop={5}
            marginBottom={5}
            title={categoryData?.featured_collection_title?.collection_name}
            description={categoryData?.featured_collection_description}
            itemGroup={categoryData?.featured_collection_title?.item_group_code}
            application={categoryData?.featured_collection_title?.application_name}
            collection={categoryData?.featured_collection_title?.collection_name}
          />

          <NewArrivalsGrid
            header='New arrivals'
            data={categoryData?.section_new_arrivals}
            hasTitles={true}
            hasButtons={true}
            hasBackgroundText={false}
            spacingValue={4}
            marginTop={'5rem'}
            marginBottom={'5rem'}
          />
          {item_group !== '000' && (
            <FilterComponent
              itemGroup={item_group}
              title={categoryData?.page_title}
              initialApplications={[]}
            />
          )}
        </>
      )}
    </PageLayout>
  );
}

export default CategoryTemplate;