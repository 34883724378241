// ApplicationsTemplate.jsx - Fixed with minimal changes

import { useQuery } from 'react-query';
import { useParams, useLocation, useNavigationType } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { apiGetApplicationPageData } from '../../../api/apiApplications';
import PageLayout from '../layout/PageLayout';
import CarouselBanner from '../banner/CarouselBanner';
import TextHeader from '../textHeader/TextHeader';
import NewArrivalsGrid from '../grid/NewArrivalsGrid';
import FilterComponent from '../filter/FilterComponent';
import LoadingScreen from '../loadingScreen/LoadingScreen';
import FeaturedGallery from '../gallery/FeaturedGallery';
import { capitalizeString } from '../../../utilities/TextManipulation';
import MissingPage from '../errorMessages/MissingPage';
import CatalogueGrid from '../grid/CatalogueGrid';
import CollectionsGrid from '../grid/CollectionsGrid';
import { catalogueData } from '../../../api/tempData';
import { captureAnalytics } from '../../../utilities/Analytics';

function ApplicationsTemplate() {
    const { item_group, application } = useParams<{ item_group?: string; application?: string }>();
    const location = useLocation();
    const navigationType = useNavigationType();

    const argumentDataStructure = {
        category: { application: application ?? '', item_group: item_group ?? '' },
    };

    const {
        data: applicationData,
        isLoading,
        isError,
    } = useQuery(
      ['application', application],
      () => apiGetApplicationPageData(argumentDataStructure),
      { staleTime: 0, enabled: !!application }
    );

    useLayoutEffect(() => {
        // Always allow browser's natural scroll restoration for back/forward navigation
        if (navigationType === 'POP') {
            // If it's a back navigation specifically from a filter product
            const isBackNavFromFilterProduct =
              sessionStorage.getItem('fromFilterComponent') === 'true' &&
              (location.pathname.includes('/application/') ||
                location.pathname.includes('/collection/'));

            if (isBackNavFromFilterProduct) {
                // Use setTimeout to ensure DOM is fully updated
                setTimeout(() => {
                    const filterElement = document.getElementById('filter-component-section');
                    if (filterElement) {
                        filterElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    // Clear the flag after using it
                    sessionStorage.removeItem('fromFilterComponent');
                }, 100);
            } else {
                // For all other back navigation, let browser handle scroll naturally
                return;
            }
        } else {
            // For all other navigation types (PUSH, REPLACE)
            // Don't scroll if we're on a page with filter params
            if (location.search.includes('page=') ||
              location.search.includes('colours=') ||
              location.search.includes('types=') ||
              location.search.includes('design_style=') ||
              location.search.includes('brand=')) {
                return;
            }

            // In all other cases, scroll to top
            window.scrollTo(0, 0);
        }
    }, [location.pathname, location.search, navigationType]);





    // Track page view for analytics
    useEffect(() => {
        if (application) {
            captureAnalytics('pageview', location.pathname, `application-${application}`);
        }
    }, [location.pathname, application]);

    if (!application) {
        return <MissingPage />;
    }

    return (
      <PageLayout>
          <>
              {isLoading && <LoadingScreen />}
              {isError && <MissingPage />}
              {applicationData && (
                <>
                    <CarouselBanner
                      heroImages={applicationData?.section_trade_hero}
                      heroLink={applicationData?.section_trade_hero_link}
                      heroVideo={applicationData?.section_trade_hero_video ? {
                          video_url: applicationData.section_trade_hero_video.video_url,
                          thumbnail_url: applicationData.section_trade_hero_video.thumbnail_url
                      } : undefined}
                      videoLink={applicationData?.section_trade_video_link}
                      promoImages={applicationData?.section_trade_promo}
                      promoLink={applicationData?.section_trade_promo_link}
                      displayOption={applicationData?.trade_display_option}
                    />
                    <TextHeader
                      heading={applicationData.page_title}
                      content={applicationData.description}
                      isCentered={false}
                      width="70%"
                      marginTop={10}
                      marginBottom={5}
                    />

                    <FeaturedGallery
                      images={applicationData.section_featured_collection}
                      hasVideo={false}
                      invert={false}
                      marginTop={5}
                      marginBottom={5}
                      title={applicationData.featured_collection_title?.collection_name}
                      description={applicationData.featured_collection_description}
                      itemGroup={applicationData.featured_collection_title?.item_group_code}
                      application={applicationData.featured_collection_title?.application_name}
                      collection={applicationData.featured_collection_title?.collection_name}
                    />

                    <div id="filter-component-section" >
                        <FilterComponent
                          itemGroup={item_group}
                          title={capitalizeString(application)}
                          navigationType={navigationType}
                        />
                    </div>

                    <CollectionsGrid
                      data={applicationData.section_collections_in_application}
                      applicationName={application}
                      marginTop={5}
                      marginBottom={5}
                    />

                    <NewArrivalsGrid
                      header="New arrivals"
                      data={applicationData.section_new_arrivals}
                      hasTitles={true}
                      hasButtons={true}
                      hasBackgroundText={false}
                      spacingValue={4}
                      marginTop="5rem"
                      marginBottom="5rem"
                    />

                    {item_group && !['001', '004', '005'].includes(item_group) && (
                      <CatalogueGrid
                        header="View our latest catalogues"
                        catalogueData={catalogueData}
                      />
                    )}
                </>
              )}
          </>
      </PageLayout>
    );
}

export default ApplicationsTemplate;