// ItemGrid.tsx
import { Grid, Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissingContent from '../errorMessages/MissingContent';

function ItemGrid({
                    data,
                    hasTitles,
                    hasButtons,
                    hasBackgroundText,
                    spacingValue,
                    marginTop,
                    marginBottom,
                  }: any) {
  const navigate = useNavigate();

  // ** Application Mapping **
  const applicationMapping: { [key: string]: string[] } = {
    'Curtaining': ['Curtaining', 'Dual Purpose'],
    'Dual Purpose': ['Dual Purpose'],
    'Upholstery': ['Upholstery', 'Dual Purpose'],
    // Add more mappings as needed for fabrics
  };

  // ** Handle Navigation **
  const handleNavigate = (applicationName: string, itemGroupCode: string) => {
    if (applicationMapping.hasOwnProperty(applicationName)) {
      // Custom mapping for fabrics
      const mappedApplications = applicationMapping[applicationName];
      const targetPath = mappedApplications[0].toLowerCase(); // e.g., 'upholstery'

      const searchParams = new URLSearchParams();
      mappedApplications.forEach(app => searchParams.append('application', app));

      const url = `/item_group/${encodeURIComponent(itemGroupCode)}/application/${encodeURIComponent(
        targetPath
      )}?${searchParams.toString()}`;
      navigate(url, { replace: false });
    } else {
      // Standard mapping for other applications
      const targetPath = applicationName.toLowerCase(); // e.g., 'bathmats'
      const searchParams = new URLSearchParams();
      searchParams.append('application', applicationName);

      const url = `/item_group/${encodeURIComponent(itemGroupCode)}/application/${encodeURIComponent(
        targetPath
      )}?${searchParams.toString()}`;
      navigate(url, { replace: false });
    }
  };

  // If no data, show missing content
  if (data === null || data.length === 0) {
    return <MissingContent sectionName="Item Group Section" />;
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        rowSpacing={'16px'}
        columnSpacing={{ xs: 1, sm: 2, md: '16px' }}
        sx={{ mt: marginTop, mb: marginBottom }}
      >
        {data.map((elem: any, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={spacingValue}>
            <Box
              sx={{
                position: 'relative',
                cursor: 'pointer',
                width: '100%',
                height: { xs: '320px', lg: '510px' },
                minHeight: '100%',
                '&:hover img': {
                  transform: 'scale(1.03)',
                },
                overflow: 'hidden',
              }}
              onClick={() => handleNavigate(elem.application_name, elem.item_group_code)}
            >
              <img
                src={elem?.url}
                loading="eager"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                  transition: 'transform 0.5s ease',
                }}
                alt={elem.application_name || "Category Image"}
              />
              {hasBackgroundText && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    background:
                      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.36) 100%)',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'lighter',
                      color: '#fff',
                      fontSize: '40px',
                      textTransform: 'capitalize',
                      mb: { xs: 3, lg: 4 },
                    }}
                  >
                    {elem.application_name.replace(/_/g, ' ')}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '1.5rem',
              }}
            >
              {hasTitles && (
                <Typography variant="h5">{elem?.item_group}</Typography>
              )}
              {hasButtons && (
                <Button
                  sx={{
                    borderRadius: '1px',
                    padding: '0.5rem 2.5rem',
                    margin: '1.5rem 0rem',
                  }}
                  variant="outlined"
                  onClick={() => handleNavigate(elem.application_name, elem.item_group_code)}
                >
                  Explore More
                </Button>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ItemGrid;