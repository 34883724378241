import { useNavigate } from 'react-router-dom';
import {
    Drawer,
    Typography,
    Box,
    Grid,
    Divider,
    Button,
    TextField,
    CircularProgress,
    IconButton,
    InputAdornment,
    Snackbar,
    Alert,
    Tabs,
    Tab
} from '@mui/material';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../../../context/AuthContext';
import { useEffect, useState } from 'react';
import { Close, Delete } from '@mui/icons-material';

function Cart({ isOpen }: any) {
    const {
        closeCart,
        cartItemList,
        increaseQuantity,
        decreaseQuantity,
        clearCart,
        subTotal,
        vatAmount,
        total,
        updateQuantity,
        loadingItemId,
        loadingDeleteItemId,
        removeFromCart,
        validateCartStock,
        // New cutting cart properties
        cuttingsCartItemList,
        increaseCuttingQuantity,
        decreaseCuttingQuantity,
        removeFromCuttingsCart,
        clearCuttingsCart,
        updateCuttingQuantity,
        activeCart,
        switchToCart,
        submitCuttingsOrder,
        isCuttingsOrderProcessing,
        loadingCuttingItemId
    } = useShoppingCart();

    const { isAccountOnHold } = useAuth();
    const [errorInput, setErrorInput] = useState(false);
    const [stockNotification, setStockNotification] = useState<string | null>(null);
    const [adjustedItems, setAdjustedItems] = useState<Array<any>>([]);
    const [cuttingErrorInput, setCuttingErrorInput] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState<string | null>(null);

    // Add this to Cart.tsx
    useEffect(() => {
        // Only run when cart is opened
        if (isOpen && cartItemList && cartItemList.length > 0) {
            // Run stock validation silently
            validateCartStock().then(result => {
                if (result && result.stockChanged && result.adjustedItems.length > 0) {
                    setAdjustedItems(result.adjustedItems);
                    setStockNotification("Oops! Some items in your cart are no longer available");
                }
            });
        }
    }, [isOpen, cartItemList.length, validateCartStock]); // Only depend on cart being opened or items count changing

    const handleQuantityChange = (
      elem: any,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault();
        const value = e.target.value;
        const numberValue = Number(value);
        const isValidInput =
          /^\d+(\.\d{0,1})?$/.test(value) && numberValue <= elem.stock;
        if (isValidInput) {
            updateQuantity(elem.id, numberValue);
            setErrorInput(false);
        } else {
            setErrorInput(true);
        }
    };

    const handleCuttingQuantityChange = (
      elem: any,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        e.preventDefault();
        const value = e.target.value;
        const numberValue = Number(value);
        const isValidInput =
          /^\d+(\.\d{0,1})?$/.test(value) && numberValue <= elem.stock;
        if (isValidInput) {
            updateCuttingQuantity(elem.id, numberValue);
            setCuttingErrorInput(false);
        } else {
            setCuttingErrorInput(true);
        }
    };



    const preventDecimal = (e: React.KeyboardEvent) => {
        const value = (e.target as HTMLInputElement).value;
        const parts = value.split('.');
        if (e.key === '.' && parts.length > 1) {
            e.preventDefault();
        }
        if (
          parts.length === 2 &&
          parts[1].length >= 1 &&
          e.key !== 'Backspace'
        ) {
            e.preventDefault();
        }
    };

    const handleCloseNotification = () => {
        setStockNotification(null);
        setAdjustedItems([]);
    };

    const handleCloseSuccessNotification = () => {
        setOrderSuccess(null);
    };

    const handleSubmitCuttingsOrder = async () => {
        try {
            const result = await submitCuttingsOrder();
            if (result.success) {
                setOrderSuccess(result.message);
            } else {
                setStockNotification(result.message);
            }
        } catch (error) {
            setStockNotification("Error submitting cuttings order. Please try again.");
        }
    };

    const calculateTotalCuttingItems = () => {
        return cuttingsCartItemList.reduce((total: number, item: any) => total + item.quantity, 0);
    };

    const canAddMoreCuttings = () => {
        return calculateTotalCuttingItems() < 20;
    };


    const navigate = useNavigate();

    const renderRegularCart = () => (
      <>
          {cartItemList.length === 0 ? (
            <Grid mt={5} container justifyContent='center'>
                <Typography fontWeight={'bold'} fontSize={'18px'}>
                    Cart is Empty
                </Typography>
            </Grid>
          ) : (
            <>
                {loadingDeleteItemId ? (
                  <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                  >
                      <CircularProgress />
                  </Box>
                ) : (
                  <>
                      {cartItemList?.map((elem: any, index: number) => (
                        <Grid
                          key={index}
                          container
                          sx={{ p: 2 }}
                          pb={2}
                        >
                            <Box
                              sx={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  bgcolor: '#FFF',
                                  borderRadius: '4px',
                                  p: '16px',
                              }}
                            >
                                <Box
                                  sx={{
                                      flex: 1,
                                      height: '92px',
                                      width: '92px',
                                  }}
                                >
                                    <img
                                      src={elem.image}
                                      alt='cart item'
                                      style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                      }}
                                    />
                                </Box>
                                <Box
                                  sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      ml: 2,
                                      flex: 4,
                                  }}
                                >
                                    <Box
                                      sx={{
                                          display: 'flex',
                                          justifyContent:
                                            'space-between',
                                          alignItems: 'center',
                                      }}
                                    >
                                        <Typography
                                          sx={{
                                              fontSize: '16px',
                                              fontWeight: '600',
                                              letterSpacing: '-0.2px',
                                              lineHeight: '24px',
                                          }}
                                        >
                                            {elem.name}
                                        </Typography>
                                        <IconButton
                                          onClick={() =>
                                            removeFromCart(elem)
                                          }
                                        >
                                            <Delete
                                              htmlColor='#000'
                                              sx={{
                                                  height: '18px',
                                                  width: '18px',
                                              }}
                                            />
                                        </IconButton>
                                    </Box>

                                    <Typography
                                      sx={{
                                          fontSize: '14px',
                                          fontWeight: '400',
                                          letterSpacing: '-0.2px',
                                          lineHeight: '21px',
                                          color: '#737373',
                                      }}
                                    >{`Description:${' '} ${
                                      elem.name
                                    }`}</Typography>
                                    <Grid
                                      container
                                      justifyContent='space-between'
                                      alignItems='flex-end'
                                    >
                                        {elem.item_group_code === '005' ? (
                                          <Box>
                                              <TextField
                                                defaultValue={
                                                    elem.quantity
                                                }
                                                type='text'
                                                onChange={(e) =>
                                                  handleQuantityChange(
                                                    elem,
                                                    e
                                                  )
                                                }
                                                variant='outlined'
                                                size='small'
                                                error={errorInput}
                                                onKeyDown={
                                                    preventDecimal
                                                }
                                                helperText={
                                                    errorInput ? (
                                                      <Typography>
                                                          There
                                                          are only{' '}
                                                          {
                                                              elem.stock
                                                          }{' '}
                                                          in
                                                          stock.
                                                      </Typography>
                                                    ) : null
                                                }
                                                InputProps={{
                                                    sx: {
                                                        width: '60%',
                                                        p: '0 0 0 1',
                                                        background:
                                                          'linear-gradient(to right, #EAECF0 50%, #FFF 50%)',
                                                        fontSize:
                                                          '14px',
                                                        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                                          {
                                                              '-webkit-appearance':
                                                                'none',
                                                              margin: 0,
                                                          },
                                                        '& input[type="number"]':
                                                          {
                                                              '-moz-appearance':
                                                                'textfield',
                                                          },
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position='end'>
                                                          <Typography
                                                            fontSize={
                                                                '14px'
                                                            }
                                                            color={
                                                                '#000'
                                                            }
                                                          >
                                                              Meters
                                                          </Typography>
                                                      </InputAdornment>
                                                    ),
                                                }}
                                              />
                                          </Box>
                                        ) : (
                                          <Box
                                            sx={{
                                                mt: 1,
                                                display: 'flex',
                                                alignItems:
                                                  'center',
                                                border: '#EAECF0 1px solid',
                                                bgcolor: '#EAECF0',
                                                ':hover': {
                                                    bgcolor:
                                                      '#EAECF0',
                                                },
                                            }}
                                          >
                                              <Button
                                                sx={{
                                                    height: '32px',
                                                    width: '32px',
                                                    padding: '0',
                                                    bgcolor: '#FFF',
                                                    ':hover': {
                                                        bgcolor:
                                                          '#FFF',
                                                    },
                                                }}
                                                disabled={
                                                  elem.quantity ===
                                                  1
                                                }
                                                onClick={() => {
                                                    decreaseQuantity(
                                                      elem
                                                    );
                                                }}
                                              >
                                                  <RemoveIcon
                                                    sx={{
                                                        cursor: 'pointer',
                                                        fontSize:
                                                          '14px',
                                                    }}
                                                  />
                                              </Button>

                                              <Typography
                                                px={3}
                                                fontSize='14px'
                                              >
                                                  {elem?.quantity}
                                              </Typography>
                                              <Button
                                                sx={{
                                                    height: '32px',
                                                    width: '32px',
                                                    padding: '0',
                                                    bgcolor: '#FFF',
                                                    ':hover': {
                                                        bgcolor: '#FFF',
                                                    },
                                                }}
                                                disabled={
                                                  loadingItemId === elem.id ||
                                                  (elem.quantity >= elem.stock_on_hand)
                                                }
                                                onClick={() => {
                                                    increaseQuantity(elem);
                                                }}
                                              >
                                                  <AddIcon
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                  />
                                              </Button>
                                          </Box>
                                        )}
                                        {loadingItemId === elem.id ? (
                                          <CircularProgress
                                            size={24}
                                          />
                                        ) : (
                                          <Typography
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}
                                          >
                                              {`R${elem?.unit_price_incl?.toFixed(
                                                2
                                              )}`}
                                          </Typography>
                                        )}
                                    </Grid>
                                    {elem?.discount_type !==
                                      'normal' && (
                                        <>
                                            <Typography
                                              fontWeight={'500'}
                                            >
                                                Discounted price
                                                applied*
                                            </Typography>
                                        </>
                                      )}
                                </Box>
                            </Box>
                        </Grid>
                      ))}
                  </>
                )}
                <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      background: '#F5F5F5',
                  }}
                >
                    <Typography
                      sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                      }}
                    >
                        Subtotal
                    </Typography>
                    <Typography
                      sx={{
                          fontSize: '18px',
                          fontWeight: '400',
                      }}
                    >{`R${subTotal?.toFixed(2)}`}</Typography>
                </Box>
                <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      background: '#F5F5F5',
                  }}
                >
                    <Typography
                      sx={{
                          fontSize: '14px',
                          color: '#545454',
                          fontWeight: '400',
                      }}
                    >
                        VAT
                    </Typography>
                    <Typography
                      sx={{
                          fontSize: '18px',
                          fontWeight: '400',
                          color: '#545454',
                      }}
                    >
                        R{vatAmount?.toFixed(2)}
                    </Typography>
                </Box>
                <Box
                  sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 2,
                      background: '#F5F5F5',
                  }}
                >
                    <Typography
                      sx={{
                          fontSize: '14px',
                          fontWeight: '400',
                          color: '#545454',
                      }}
                    >
                        Total
                    </Typography>
                    <Typography
                      sx={{
                          fontSize: '24px',
                          fontWeight: '600',
                          color: '#333',
                      }}
                    >
                        R{total?.toFixed(2)}
                    </Typography>
                </Box>
                {isAccountOnHold && (
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'red',
                        background: '#F5F5F5',
                    }}
                  >
                      <Typography>
                          Your account has been placed on hold.{' '}
                      </Typography>
                      <Typography>
                          Please contact the Hertex team for assistance.{' '}
                      </Typography>
                  </Box>
                )}

                <Box
                  pt={3}
                  pb={3}
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Button
                      variant='contained'
                      sx={{ width: '80%' }}
                      disableElevation
                      onClick={() => {
                          clearCart();
                      }}
                    >
                        Clear Cart
                    </Button>
                </Box>
                <Divider />
                <Box
                  pt={3}
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Button
                      variant='contained'
                      disabled={isAccountOnHold}
                      sx={{ width: '80%' }}
                      onClick={() => {
                          closeCart();
                          navigate('/checkout');
                      }}
                      disableElevation
                    >
                        Place Order
                    </Button>
                </Box>

                <Box
                  pt={3}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Typography fontWeight='600'>
                        Shipping, taxes and discount codes{' '}
                    </Typography>
                    <Typography fontWeight='600'>
                        calculated at checkout{' '}
                    </Typography>
                </Box>
            </>
          )}
      </>
    );

    const renderCuttingsCart = () => (
      <>
          {cuttingsCartItemList.length === 0 ? (
            <Grid mt={5} container justifyContent='center'>
                <Typography fontWeight={'bold'} fontSize={'18px'}>
                    Cuttings Cart is Empty
                </Typography>
            </Grid>
          ) : (
            <>
                <Box sx={{ p: 2, bgcolor: '#f0f0f0', borderRadius: '4px', mb: 2 }}>
                    <Typography fontSize="14px" fontWeight="500" color={calculateTotalCuttingItems() >= 20 ? 'error.main' : 'text.primary'}>
                        Total Cuttings: {calculateTotalCuttingItems()}/20
                    </Typography>
                    <Typography fontSize="12px" color="text.secondary">
                        Maximum 3 cuts per fabric, 20 cuts total per order
                    </Typography>
                </Box>

                {cuttingsCartItemList?.map((elem: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    sx={{ p: 2 }}
                    pb={2}
                  >
                      <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            bgcolor: '#FFF',
                            borderRadius: '4px',
                            p: '16px',
                        }}
                      >
                          <Box
                            sx={{
                                flex: 1,
                                height: '92px',
                                width: '92px',
                            }}
                          >
                              <img
                                src={elem.image}
                                alt='cutting item'
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                              />
                          </Box>
                          <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                ml: 2,
                                flex: 4,
                            }}
                          >
                              <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                              >
                                  <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        letterSpacing: '-0.2px',
                                        lineHeight: '24px',
                                    }}
                                  >
                                      {elem.name}
                                  </Typography>
                                  <IconButton
                                    onClick={() =>
                                      removeFromCuttingsCart(elem)
                                    }
                                  >
                                      <Delete
                                        htmlColor='#000'
                                        sx={{
                                            height: '18px',
                                            width: '18px',
                                        }}
                                      />
                                  </IconButton>
                              </Box>

                              <Typography
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    letterSpacing: '-0.2px',
                                    lineHeight: '21px',
                                    color: '#737373',
                                }}
                              >{`Description:${' '} ${
                                elem.name
                              }`}</Typography>
                              <Grid
                                container
                                justifyContent='space-between'
                                alignItems='flex-end'
                              >
                                  <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: '#EAECF0 1px solid',
                                        bgcolor: '#EAECF0',
                                        ':hover': {
                                            bgcolor: '#EAECF0',
                                        },
                                    }}
                                  >
                                      <Button
                                        sx={{
                                            height: '32px',
                                            width: '32px',
                                            padding: '0',
                                            bgcolor: '#FFF',
                                            ':hover': {
                                                bgcolor: '#FFF',
                                            },
                                        }}
                                        disabled={elem.quantity === 1}
                                        onClick={() => {
                                            decreaseCuttingQuantity(elem);
                                        }}
                                      >
                                          <RemoveIcon
                                            sx={{
                                                cursor: 'pointer',
                                                fontSize: '14px',
                                            }}
                                          />
                                      </Button>

                                      <Typography px={3} fontSize='14px'>
                                          {elem?.quantity}
                                      </Typography>

                                      <Button
                                        sx={{
                                            height: '32px',
                                            width: '32px',
                                            padding: '0',
                                            bgcolor: '#FFF',
                                            ':hover': {
                                                bgcolor: '#FFF',
                                            },
                                        }}
                                        disabled={
                                          loadingCuttingItemId === elem.id ||
                                          elem.quantity >= 3 ||
                                          !canAddMoreCuttings()
                                        }
                                        onClick={() => {
                                            increaseCuttingQuantity(elem);
                                        }}
                                      >
                                          <AddIcon
                                            sx={{
                                                fontSize: '14px',
                                            }}
                                          />
                                      </Button>
                                  </Box>
                                  {loadingCuttingItemId === elem.id && (
                                    <CircularProgress size={24} />
                                  )}
                              </Grid>
                          </Box>
                      </Box>
                  </Grid>
                ))}

                <Box
                  pt={3}
                  pb={3}
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Button
                      variant='contained'
                      sx={{ width: '80%' }}
                      disableElevation
                      onClick={() => {
                          clearCuttingsCart();
                      }}
                    >
                        Clear Cuttings Cart
                    </Button>
                </Box>
                <Divider />
                <Box
                  pt={3}
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Button
                      variant='contained'
                      disabled={
                        isAccountOnHold ||
                        isCuttingsOrderProcessing ||
                        cuttingsCartItemList.length === 0
                      }
                      sx={{ width: '80%' }}
                      onClick={() => {
                          closeCart();
                          navigate('/checkout');
                      }}
                      disableElevation
                    >
                        Place Cuttings Order
                    </Button>
                </Box>

                <Box
                  pt={3}
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F5F5F5',
                  }}
                >
                    <Typography fontWeight='600'>
                        Note: Cuttings orders are processed separately
                    </Typography>
                    <Typography fontWeight='600'>
                        from regular product orders
                    </Typography>
                </Box>
            </>
          )}
      </>
    );

    const list = () => (
      <Box
        sx={{
            width: { xs: '100vw', sm: 550 },
            height: '100%',
            background: '#F5F5F5',
        }}
        role='presentation'
      >
          <Box p={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Close
                onClick={() => {
                    closeCart();
                }}
                sx={{ cursor: 'pointer', fontSize: '30px' }}
              />

              <Typography
                sx={{
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '22px',
                }}
              >
                  {activeCart === 'regular' ? 'My Cart' : 'Cuttings Cart'}
              </Typography>

              <Box sx={{ width: '30px' }}></Box> {/* Empty box for alignment */}
          </Box>

          {/* Cart Type Tabs */}
          <Tabs
            value={activeCart}
            onChange={(_, newValue) => switchToCart(newValue)}
            centered
            sx={{
                mb: 2,
                '& .MuiTabs-indicator': {
                    backgroundColor: '#000',
                },
                '& .MuiTab-root': {
                    color: '#737373',
                    '&.Mui-selected': {
                        color: '#000',
                        fontWeight: 'bold',
                    },
                },
            }}
          >
              <Tab label="Regular Cart" value="regular" />
              <Tab label="Cuttings Cart" value="cuttings" />
          </Tabs>

          {/* Render appropriate cart based on active tab */}
          {activeCart === 'regular' ? renderRegularCart() : renderCuttingsCart()}
      </Box>
    );

    return (
      <>
          <Drawer anchor={'right'} open={isOpen} onClose={closeCart}>
              {list()}
          </Drawer>

          {/* Toast notification for stock changes */}
          <Snackbar
            open={!!stockNotification}
            autoHideDuration={6000}
            onClose={handleCloseNotification}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
              <Alert
                onClose={handleCloseNotification}
                severity="info"
                sx={{ width: '100%' }}
              >
                  {stockNotification}
                  {adjustedItems.length > 0 && (
                    <Box sx={{ mt: 1 }}>
                        {adjustedItems.map((item, index) => (
                          <Typography key={index} variant="body2">
                              {item.status === 'removed' ?
                                `${item.name}: Removed due to lack of stock` :
                                `${item.name}: Adjusted from ${item.oldQuantity} to ${item.newQuantity}`
                              }
                          </Typography>
                        ))}
                    </Box>
                  )}
              </Alert>
          </Snackbar>

          {/* Toast notification for successful cuttings order */}
          <Snackbar
            open={!!orderSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSuccessNotification}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
              <Alert
                onClose={handleCloseSuccessNotification}
                severity="success"
                sx={{ width: '100%' }}
              >
                  {orderSuccess}
              </Alert>
          </Snackbar>
      </>
    );
}

export default Cart;