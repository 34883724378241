import  { useMemo, useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  FormControl,
  InputAdornment,
  TextField,
  MenuItem,
  Select,
  Tooltip,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useShoppingCart } from '../../../../../context/ShoppingCartContext';
import { useAuth } from '../../../../../context/AuthContext';
import { useQuery } from 'react-query';
import { apiGetAllBrands } from '../../../../../api/apiBrands';
import { apiGetStockRollData } from '../../../../../api/apiProducts';
import StockRollModal from '../../../modal/StockRollModal';
import { PromoProduct} from '../../promo/types';

interface ImageMatch {
  image_data?: string;
  file_name?: string;
  brand_name?: string;
}



interface ProductSectionProps {
  productData: any[];        // Remove undefined
  productListData: any[];
  isPromoProduct: boolean;
  promoData: {
    id: string;
    product_sku: string;
    original_price_excl: string;
    promo_price_excl: string;
    original_price_incl: string;
    promo_price_incl: string;
  } | null | undefined;
  designPromoProducts: PromoProduct[] | null | undefined;
  isLoadingDesignPromos?: boolean;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  itemGroup: string | undefined;
  application: string | undefined;
  collection: string | undefined;
  design: string | undefined;
  colour: string | undefined;
  currentSelectedItem: any;
  setCurrentSelectedItem: (item: any) => void;
  setSelectedImagePreviewIndex: (index: number) => void;
  selectedImagePreviewIndex: number;
  setWidth: (width: string) => void;
  setLengthCM: (length: string) => void;
  setDiameter: (diameter: string) => void;
  diameter: string | undefined;
  width: string | undefined;
  lengthCM: string | undefined;
  activeImageStep: number;
  setActiveImageStep: (step: number) => void;
  promoMap?: Map<string, any>;
  isInitialLoad?: boolean;
  setIsInitialLoad?: (value: boolean) => void;
}

const ProductSection = ({
                          productData,
                          productListData,
                          isPromoProduct,
                          promoData,
                          designPromoProducts,
                          currentIndex,
                          setCurrentIndex,
                          itemGroup,
                          application,
                          collection,
                          design,
                          colour,
                          currentSelectedItem,
                          setCurrentSelectedItem,
                          setSelectedImagePreviewIndex,
                          selectedImagePreviewIndex,
                          setWidth,
                          setLengthCM,
                          setDiameter,
                          diameter,
                          width,
                          lengthCM,
                          activeImageStep,
                          setActiveImageStep,
                        } : ProductSectionProps) => {
  const base = window.location.origin;



  const [selectedSize, setSelectedSize] = useState('');
  const [isFromDropdown, setIsFromDropdown] = useState(false);
  const { isLogin } = useAuth();
  const { addToCart, setStockLink, discountAmount, addToCuttingsCart  } = useShoppingCart();
  const [more, setMore] = useState(window.innerWidth > 600 ? 6 : 3);
  const [imageMatch, setImageMatch] = useState<ImageMatch>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categoryQuantity, setCategoryQuantity] = useState<number | ''>(1);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [rugSizes, setRugSizes] = useState<Array<{ dimensions: string }>>([]);




  // Create a map of SKUs to promo data for efficient lookup
  const promoMap = useMemo(() => {
    if (!designPromoProducts) return new Map();
    return new Map(
      designPromoProducts.map(promo => [promo.product_sku, promo])
    );
  }, [designPromoProducts]);

  // Check if current product is a promo product
  const currentPromoInfo = useMemo(() => {
    if (isPromoProduct) return promoData;
    const currentProduct = productListData[currentIndex];
    return promoMap.get(currentProduct?.Product?.sku || '');
  }, [isPromoProduct, promoData, promoMap, productListData, currentIndex]);

  const handleOpenModal = () => {
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const {
    isSuccess,
    isError,
    isLoading,
    data: stockRollData,
    refetch,
  } = useQuery(
    [`stockroll-${currentSelectedItem?.sku}`],
    () => apiGetStockRollData(currentSelectedItem?.sku),
    { staleTime: 600000 }
  );

  const productDataItems = productData?.slice(0, -1) || [];

  const showMore = () => {
    setMore(productDataItems?.length);
  };

  const showLess = () => {
    setMore(window.innerWidth > 600 ? 6 : 3);
  };

  const calculateTradePrice = () => {
    // First determine the base price based on promotion status
    let basePrice;

    if (isPromoProduct) {
      basePrice = Number(promoData?.promo_price_excl);
    } else if (currentPromoInfo) {
      basePrice = Number(currentPromoInfo.promo_price_excl);
    } else {
      basePrice = Number(productListData[currentIndex]?.StockMaster?.cut_price_ex_vat);
    }

    // Apply discount if available
    if (discountAmount?.discount_total && !isPromoProduct && !currentPromoInfo) {
      return discountAmount.discount_total;
    }

    return basePrice;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    // Only run this once when the component mounts or when productListData changes significantly
    const urlParams = new URLSearchParams(window.location.search);
    const targetProductSku = urlParams.get('targetProduct');
    const targetSize = urlParams.get('targetSize');

    console.log("URL parameter check:", {
      targetProductSku,
      targetSize,
      productListDataLoaded: productListData?.length > 0
    });

    // Only proceed if we have both the target SKU and data is loaded
    if (targetProductSku && productListData && productListData.length > 0) {
      // Find the exact index of the product with matching SKU
      const targetIndex = productListData.findIndex(
        item => item.Product?.sku === targetProductSku
      );

      console.log("Target product search:", {
        targetSku: targetProductSku,
        found: targetIndex !== -1,
        targetIndex: targetIndex,
        currentIndex: currentIndex
      });

      // Only update if we found a matching product and it's different from current
      if (targetIndex !== -1 && targetIndex !== currentIndex) {
        console.log(`Setting product to index ${targetIndex} with SKU ${targetProductSku}`);

        // Set indexes once, using a ref to prevent multiple calls
        const product = productListData[targetIndex];

        // Directly update state in one batch to minimize renders
        setCurrentIndex(targetIndex);
        setSelectedImagePreviewIndex(targetIndex);

        // Update dimensions based on the product
        if (product?.StockMaster) {
          if (product.StockMaster.diameter && product.StockMaster.diameter !== '0cm') {
            setDiameter(product.StockMaster.diameter.trim());
            setWidth('');
            setLengthCM('');
          } else {
            setWidth(product.StockMaster.width.trim());
            setLengthCM(product.StockMaster.length_cm.trim());
            setDiameter('');
          }
        }
      }
    }
  }, []);  // Empty dependency array means this runs only once on mount

  useEffect(() => {
    if (!isFromDropdown) {
      const currentProduct = productListData[currentIndex];
      const tradePrice = calculateTradePrice();

      setCurrentSelectedItem({
        image: currentProduct?.Product?.primaryImageUrl,
        name: currentProduct?.Product?.colour,
        quantity: categoryQuantity,
        price: tradePrice,
        stock: currentProduct?.StockMaster?.on_hand,
        sku: currentProduct?.Product?.sku,
        stock_link: currentProduct?.StockMaster?.stock_link,
        item_group_code: currentProduct?.StockMaster?.item_group,
        description: currentProduct?.StockMaster?.description_1,
        group: currentProduct?.StockMaster?.group_desc,
        id: currentProduct?.Product?.id,
        discount_type: discountAmount?.discount_type,
        isPromo: isPromoProduct || !!currentPromoInfo,
        availableColourImages:
          currentProduct?.Product?.additionalImages === null
            ? [{ image_data: currentProduct?.Product?.primaryImageUrl }]
            : currentProduct?.Product?.additionalImages,
      });
    }
  }, [
    currentIndex,
    productListData,
    setCurrentSelectedItem,
    calculateTradePrice,
    categoryQuantity,
    discountAmount?.discount_type,
    discountAmount?.discount_total, // Added this dependency
    isFromDropdown,
    isPromoProduct,
    currentPromoInfo
  ]);

  useEffect(() => {
    const updatedStockLink = productListData[currentIndex]?.StockMaster?.stock_link;
    if (isLogin && updatedStockLink) {
      setStockLink(updatedStockLink);
    }
  }, [currentIndex, productListData, setStockLink, isLogin]);

  useQuery(
    ['brands', productListData[currentIndex]?.StockMaster?.brand],
    async () => {
      const response = await apiGetAllBrands();
      if (response) {
        const foundImageMatch = response.find(
          (element: any) =>
            element.brand_name === productListData[currentIndex]?.StockMaster?.brand
        );
        setImageMatch(foundImageMatch);
      }
      return response;
    },
    {
      initialData: [],
    }
  );

  const getUniqueProducts = (products: any[]) => {

    const uniqueProducts = new Map();

    products.forEach((product) => {
      const key = `${product.StockMaster?.design}_${product.StockMaster?.colour}`;

      if (!uniqueProducts.has(key)) {
        // Store the first size variant as the default
        uniqueProducts.set(key, {
          ...product,
          allSizes: [{
            width: product.StockMaster?.width,
            length: product.StockMaster?.length_cm,
            diameter: product.StockMaster?.diameter,
            index: products.indexOf(product)
          }]
        });
      } else {
        // Add size variant to existing product
        const existingProduct = uniqueProducts.get(key);
        existingProduct.allSizes.push({
          width: product.StockMaster?.width,
          length: product.StockMaster?.length_cm,
          diameter: product.StockMaster?.diameter,
          index: products.indexOf(product)
        });
      }
    });

    const result = Array.from(uniqueProducts.values());


    return result;
  };

  useEffect(() => {
    if (!productData || productData.length === 0) return;

    const lastItem = productData[productData.length - 1];
    const rugDiameters = lastItem.required_full_key_info.diameter
      .filter((elem: any) => !/^0*\.?0+\s*(?:cm)?$/i.test(elem))
      .map((elem: any) => ({ dimensions: elem }));
    const rugHeights = lastItem.required_full_key_info.length_cm.map(
      (elem: any) => ({ height: elem })
    );
    const updatedRugSizes = lastItem.required_full_key_info.width
      .map((elem: any, index: number) => ({
        dimensions: `${elem}x${rugHeights[index]?.height}`,
      }))
      .filter(
        (elem: any) =>
          !/^(0*\.?0+\s*(?:cm)?\s*[xX]\s*0*\.?0+\s*(?:cm)?|0*\.?0+\s*(?:cm)?)$/i.test(
            elem.dimensions
          )
      );

    const allSizes = [...updatedRugSizes, ...rugDiameters];

    setRugSizes(allSizes);

    // Only set selectedSize if needed
    if (!selectedSize && allSizes.length > 0) {
      if (width && lengthCM) {
        // Try formats with and without 'cm'
        const possibleFormats = [
          `${width}x${lengthCM}`,
          `${width}cmx${lengthCM}cm`,
          `${width} x ${lengthCM}`,
          `${width}cm x ${lengthCM}cm`
        ];

        // Find the first matching format
        let matchingSize = null;
        for (const format of possibleFormats) {
          matchingSize = allSizes.find(size => size.dimensions === format);
          if (matchingSize) break;
        }

        if (matchingSize) {

          setSelectedSize(matchingSize.dimensions);
        } else {

          setSelectedSize(allSizes[0].dimensions.trim());
        }
      } else if (diameter) {
        const matchingSize = allSizes.find(size => size.dimensions === diameter);
        if (matchingSize) {

          setSelectedSize(matchingSize.dimensions);
        } else {

          setSelectedSize(allSizes[0].dimensions.trim());
        }
      } else {

        setSelectedSize(allSizes[0].dimensions.trim());
      }
    }
  }, [productData, width, lengthCM, diameter]);




  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    const selectedSizeValue = event.target.value;

    setIsFromDropdown(true);
    // Don't set isInitialLoad to false here

    setSelectedSize(selectedSizeValue);

    event.preventDefault();
    event.stopPropagation();

    if (productData && productData.length > 0) {
      const dimensions = selectedSizeValue.split('x').map((s: string) => s.trim());

      // Get current color
      const currentDesign = productListData[currentIndex]?.StockMaster?.design;
      const currentColor = productListData[currentIndex]?.StockMaster?.colour;


      // Find all products with the same design at the new size
      const matchingIndices = productListData.reduce((acc: number[], item: any, index: number) => {
        const itemDesign = item.StockMaster?.design;

        if (itemDesign === currentDesign) {
          if (dimensions.length === 2) {
            const [selectedWidth, selectedLength] = dimensions;
            if (item.StockMaster.width.trim() === selectedWidth &&
              item.StockMaster.length_cm.trim() === selectedLength) {
              acc.push(index);
            }
          } else if (item.StockMaster.diameter.trim() === dimensions[0]) {
            acc.push(index);
          }
        }
        return acc;
      }, []);



      if (matchingIndices.length > 0) {
        const currentColorIndex = matchingIndices.find(index =>
          productListData[index]?.StockMaster?.colour === currentColor
        );

        const targetIndex = currentColorIndex !== undefined ? currentColorIndex : matchingIndices[0];


        // Update all the state variables
        if (dimensions.length === 2) {
          const [selectedWidth, selectedLength] = dimensions;
          setWidth(selectedWidth);
          setLengthCM(selectedLength);
          setDiameter('');
        } else {
          setDiameter(dimensions[0]);
          setWidth('');
          setLengthCM('');
        }

        setCurrentIndex(targetIndex);
        setSelectedImagePreviewIndex(targetIndex);
        setActiveImageStep(0);

        // Get the new product
        const currentProduct = productListData[targetIndex];

        // For promo products, re-check the promo information for the new product
        let newPromoInfo = null;
        if (isPromoProduct) {
          // For directly loaded promo products, continue using promoData
          newPromoInfo = promoData;

        } else if (promoMap) {
          // For products that might have promo prices, check the promoMap with the new SKU
          const sku = currentProduct?.Product?.sku;
          if (sku) {
            newPromoInfo = promoMap.get(sku);

          }
        }

        // Calculate the trade price based on the new selection and promo status
        const newTradePrice = isPromoProduct
          ? Number(promoData?.promo_price_excl)
          : newPromoInfo
            ? Number(newPromoInfo.promo_price_excl)
            : Number(currentProduct?.StockMaster?.cut_price_ex_vat);



        const newSelectedItem = {
          image: currentProduct?.Product?.primaryImageUrl,
          name: currentProduct?.Product?.colour,
          quantity: categoryQuantity,
          price: newTradePrice,
          stock: currentProduct?.StockMaster?.on_hand,
          sku: currentProduct?.Product?.sku,
          stock_link: currentProduct?.StockMaster?.stock_link,
          item_group_code: currentProduct?.StockMaster?.item_group,
          description: currentProduct?.StockMaster?.description_1,
          group: currentProduct?.StockMaster?.group_desc,
          id: currentProduct?.Product?.id,
          discount_type: discountAmount?.discount_type,
          isPromo: isPromoProduct || !!newPromoInfo,
          availableColourImages:
            currentProduct?.Product?.additionalImages === null
              ? [{ image_data: currentProduct?.Product?.primaryImageUrl }]
              : currentProduct?.Product?.additionalImages,
        };



        setCurrentSelectedItem(newSelectedItem);
      }
    }
  };



  const handleThumbnailClick = (index: number, event: React.MouseEvent<HTMLDivElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const item = productListData[index];
    if (!item) return;

    // Ensure we're not in initial load state
    setIsFromDropdown(false);

    const width = item.StockMaster.width.trim();
    const length = item.StockMaster.length_cm.trim();
    const diameter = item.StockMaster.diameter.trim();

    // Update all states synchronously
    Promise.resolve().then(() => {

      setSelectedImagePreviewIndex(index);
      setCurrentIndex(index);

      if (width !== '0cm' && length !== '0cm') {
        setWidth(width);
        setLengthCM(length);
        setDiameter('');
        setSelectedSize(`${width}x${length}`);
      } else if (diameter !== '0cm') {
        setDiameter(diameter);
        setWidth('');
        setLengthCM('');
        setSelectedSize(diameter);
      }
    });
  };



  const uniqueProducts = useMemo(() => getUniqueProducts(productListData), [productListData]);


  const getProductsAtCurrentSize = useMemo(() => {
    if (!selectedSize) {

      return uniqueProducts;
    }

    const dimensions = selectedSize.split('x').map((s: string) => s.trim());
    const currentDesign = productListData[currentIndex]?.StockMaster?.design;


    // Add another log to see the filtered products
    const filteredProducts = productListData.filter((product: any) => {
      // Check design first
      if (product.StockMaster?.design !== currentDesign) {
        return false;
      }

      // Normalize dimensions by removing "cm" and decimal zeros
      const normalizeValue = (val: string) => {
        // Remove "cm", trim whitespace
        const noUnits = (val || "").replace(/cm/gi, "").trim();

        // Convert to number and back to string to normalize decimal zeros
        // e.g., "0.00" becomes "0", "137.00" becomes "137"
        const normalized = parseFloat(noUnits) + "";


        return normalized;
      };

      const productWidth = normalizeValue(product.StockMaster?.width);
      const productLength = normalizeValue(product.StockMaster?.length_cm);
      const productDiameter = normalizeValue(product.StockMaster?.diameter);

      if (dimensions.length === 2) {
        const [width, length] = dimensions.map(normalizeValue);

        const isMatch = productWidth === width && productLength === length;


        return isMatch;
      } else {
        const diameter = normalizeValue(dimensions[0]);
        const isMatch = productDiameter === diameter;


        return isMatch;
      }
    });



    return filteredProducts;
  }, [selectedSize, productListData, currentIndex]);


  const previousProductListDataRef = useRef<any[] | null>(null);

  useEffect(() => {
    // First check if the references are the same (fast check)
    if (previousProductListDataRef.current === productListData) {
      return; // Skip if same reference
    }

    // Then do the expensive deep comparison only if needed
    if (
      previousProductListDataRef.current &&
      JSON.stringify(productListData) === JSON.stringify(previousProductListDataRef.current)
    ) {
      return; // Skip if same data but different reference
    }

    // Log the data
    console.log("ProductListData loaded:", {
      length: productListData?.length,
      currentIndex,
      currentSku: productListData[currentIndex]?.Product?.sku,
      allSkus: productListData?.map(p => p.Product?.sku)
    });

    // CRITICAL: Update the reference after processing
    previousProductListDataRef.current = productListData;

  }, [productListData, currentIndex]); // Include currentIndex since we use it in the log

  const timeStamp = Number(
    productListData[currentIndex]?.StockMaster?.po_delivery_date
  );
  const date = new Date(timeStamp);
  const isValidDate = timeStamp && date.getTime() !== 0;

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const renderPricing = () => {
    const currentProduct = productListData[currentIndex];
    if (!currentProduct) return null;

    if (isPromoProduct || currentPromoInfo) {
      const priceData = isPromoProduct ? promoData : currentPromoInfo;

      return (
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}>
          {/* Was Price */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}>
            <Typography
              fontSize={'11px'}
              textTransform={'uppercase'}
              fontWeight={'700'}
              color="#C41E3A"
            >
              Was (excl VAT)
            </Typography>
            <Typography
              fontWeight="700"
              fontSize={'12px'}
              color="#C41E3A"
            >
              R{((priceData.original_price_excl * (categoryQuantity || 1)).toFixed(2))}
            </Typography>
          </Box>

          {/* Now Price */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '4px',
          }}>
            <Typography
              fontSize={'11px'}
              textTransform={'uppercase'}
              fontWeight={'700'}
              color="#C41E3A"
            >
              Now (excl VAT)
            </Typography>
            <Typography
              fontWeight="700"
              fontSize={'12px'}
              color="#C41E3A"
            >
              R{((priceData.promo_price_excl * (categoryQuantity || 1)).toFixed(2))}
            </Typography>
          </Box>
        </Box>
      );
    }

    // Regular pricing display
    return (
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'start',
      }}>
        {currentProduct.StockMaster?.group_desc === 'Fabric' ? (
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Cut Price (ex VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.cut_price_ex_vat * (categoryQuantity ? categoryQuantity : 1)).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
              marginTop: '4px',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Recommended Retail Price (incl VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.price_retail_inc_vat * (categoryQuantity ? categoryQuantity : 1)).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        ) : currentProduct.StockMaster?.group_desc === 'Wallpaper' ? (
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Cut Price (ex VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.cut_price_ex_vat * (categoryQuantity ? categoryQuantity : 1)).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
              marginTop: '4px',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Recommended Retail Price (incl VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.price_retail_inc_vat * (categoryQuantity ? categoryQuantity : 1)).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Unit Price (ex VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.cut_price_ex_vat * (categoryQuantity ? Number(categoryQuantity) : 1)).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
              marginTop: '4px',
            }}>
              <Typography
                fontSize={'11px'}
                textTransform={'uppercase'}
                fontWeight={'700'}
              >
                Retail Price (incl VAT)
              </Typography>
              <Typography fontWeight="700" fontSize={'12px'}>
                R{(currentProduct.StockMaster?.price_retail_inc_vat * (categoryQuantity ? Number(categoryQuantity) : 1)).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      pr: { xs: '0', lg: '50px' },
      overflow: 'hidden',
    }}>
      <Box sx={{
        padding: {
          xs: '0 0',
          lg: '0 2rem',
        },
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Breadcrumbs
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link underline="hover" color="inherit" href={`${base}/item_group/${itemGroup}`}>
            {productListData[0]?.StockMaster?.group_desc || 'Group'}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`${base}/item_group/${itemGroup}/application/${encodeURIComponent(application || '')}`}
          >
            {application || 'Application'}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            href={`${base}/item_group/${itemGroup}/application/${encodeURIComponent(
              application || ''
            )}/collection/${encodeURIComponent(collection || '')}`}
          >
            {collection || 'Collection'}
          </Link>
          <Typography color="text.primary">{design || 'Design'}</Typography>
        </Breadcrumbs>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 'auto',
        }}>
          <Box>
            <Typography
              mt={1}
              variant="h5"
              fontSize={'40px'}
              fontWeight={'500'}
              lineHeight={'40px'}
            >
              {productListData[0]?.StockMaster?.design || 'Design Name'}
            </Typography>
            <Typography fontWeight="600" lineHeight={'24px'} fontSize={'16px'}>
              {productListData[currentIndex]?.StockMaster?.colour || 'Colour'}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}>
            <img
              src={imageMatch?.image_data || '/default-brand.png'}
              alt={imageMatch?.brand_name || 'Brand'}
              style={{
                objectFit: 'contain',
                width: '30%',
              }}
            />
          </Box>
        </Box>

        {productListData[currentIndex]?.Product?.long_description && (
          <Typography
            mt={2}
            fontSize={'16px'}
            fontWeight={'400'}
            lineHeight={'25px'}
          >
            {productListData[currentIndex]?.Product?.long_description.length > 500
              ? showFullDescription
                ? productListData[currentIndex]?.Product?.long_description
                : productListData[currentIndex]?.Product?.long_description.substring(0, 500) + '...'
              : productListData[currentIndex]?.Product?.long_description}
            {productListData[currentIndex]?.Product?.long_description.length > 500 && (
              <Button
                size="small"
                onClick={toggleDescription}
                sx={{
                  textTransform: 'none',
                  cursor: 'pointer',
                  p: '0',
                  height: '0',
                  minWidth: '0',
                  mt: 2,
                }}
              >
                {showFullDescription ? 'Less' : 'More'}
              </Button>
            )}
          </Typography>
        )}

        {(itemGroup === '004' || itemGroup === '000') && (
          <Box sx={{
            mt: '10px',
            width: { xs: '100%', lg: '50%' },
          }}>
            <Box sx={{
              mt: 2,
              width: '100%',
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontWeight: '600',
                color: '#344054',
              }}>
                Available Sizes:
              </Typography>

              <FormControl fullWidth>
                <Select
                  labelId="size-select-label"
                  id="size-select"
                  size="small"
                  inputProps={{
                    sx: {
                      p: '10px 14px',
                    },
                  }}
                  onChange={handleSizeChange}
                  value={selectedSize}
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {rugSizes.map((size: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={size.dimensions.trim()}
                    >
                      {size.dimensions.includes('x')
                        ? `${size.dimensions.split('x')[0].trim()} W x ${size.dimensions.split('x')[1].trim()} L`
                        : `${size.dimensions} - Diameter`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}

        <Box sx={{
          mt: '10px',
          width: '100%',
        }}>
          <FormControl sx={{
            width: { xs: '100%', lg: '20%' },
          }}>
            <Typography sx={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#344054',
            }}>
              Quantity
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="number"
              value={categoryQuantity}
              size="small"
              inputProps={{
                sx: {
                  p: '10px 14px',
                },
              }}
              onChange={(event) => {
                const value = event.target.value;
                if (productListData[currentIndex]?.StockMaster?.group_desc === 'Fabric') {
                  const regex = /^\d*\.?\d{0,1}$/;
                  if (value === '' || regex.test(value)) {
                    setCategoryQuantity(value === '' ? '' : Number(value));
                  }
                } else {
                  const regex = /^\d*$/;
                  if (value === '' || regex.test(value)) {
                    setCategoryQuantity(value === '' ? '' : Number(value));
                  }
                }
              }}
              InputProps={{
                sx: {
                  width: '100%',
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  '& input[type="number"]': {
                    '-moz-appearance': 'textfield',
                  },
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{
                      fontWeight: '400',
                      color: '#000',
                      fontSize: '14px',
                    }}>
                      {productListData[currentIndex]?.StockMaster?.group_desc === 'Fabric'
                        ? 'm'
                        : productListData[currentIndex]?.StockMaster?.group_desc === 'Wallpaper'
                          ? 'Roll(s)'
                          : 'unit'}
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          justifyContent: 'space-between',
          gap: { xs: '2px', lg: '8px' },
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#F7F7F7',
            width: '100%',
            p: '12px 16px',
            mt: '1rem',
          }}>
            {renderPricing()}
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'start',
            backgroundColor: '#F7F7F7',
            width: '100%',
            p: '12px 16px',
            mt: '1rem',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              {productListData[currentIndex]?.StockMaster?.group_desc === 'Fabric' ? (
                <Typography
                  fontSize={'11px'}
                  textTransform={'uppercase'}
                  fontWeight={'700'}
                >
                  Meters on Hand
                </Typography>
              ) : productListData[currentIndex]?.StockMaster?.group_desc === 'Wallpaper' ? (
                <Typography
                  fontSize={'11px'}
                  textTransform={'uppercase'}
                  fontWeight={'700'}
                >
                  Rolls on Hand
                </Typography>
              ) : (
                <Typography
                  fontSize={'11px'}
                  textTransform={'uppercase'}
                  fontWeight={'700'}
                >
                  Units on Hand
                </Typography>
              )}
              {itemGroup === '005' && (
                <Button
                  disableElevation
                  variant="text"
                  sx={{
                    background: '#F2F4F7',
                    color: '#000',
                    height: '0',
                    minWidth: '0',  // Fixed the duplicate property
                    p: 0,
                  }}
                  onClick={() => {
                    refetch();
                    handleOpenModal();
                  }}
                >
                  <Typography
                    fontWeight="700"
                    textTransform={'uppercase'}
                    fontSize={'11px'}
                  >
                    View
                  </Typography>
                </Button>
              )}
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
            }}>
              <Box>
                <Typography fontWeight="700" fontSize={'12px'}>
                  {parseInt(productListData[currentIndex]?.StockMaster?.on_hand || '0').toFixed()}
                </Typography>
              </Box>
            </Box>
          </Box>

          {isValidDate && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'start',
              backgroundColor: '#F7F7F7',
              width: '100%',
              p: '12px 16px',
              mt: '1rem',
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                p: '0'
              }}>
                <Typography
                  fontSize={'11px'}
                  textTransform={'uppercase'}
                  fontWeight={'700'}
                >
                  NEXT ETA
                </Typography>
                <Tooltip title="Please note that the provided date is an estimate and may be subject to change. Please allow for delivery time.">
                  <IconButton
                    sx={{
                      width: '24px',
                      height: '24px',
                      p: 0,
                    }}
                    color="inherit"
                  >
                    <HelpOutline sx={{ fontSize: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box>
                <Typography fontWeight="700" fontSize={'11px'} lineHeight={2}>
                  {year === 1900 && month === 1 && day === 1
                    ? 'To Be Confirmed'
                    : `${day}.${month}.${year}`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>



        <Box sx={{
          mt: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            width: 'auto',
            cursor: 'pointer',
            gap: '16px',
            overflowX: getProductsAtCurrentSize?.length >= 6 ? 'auto' : 'hidden',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
              border: '2px solid transparent',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#888 transparent',
          }}>

            {getProductsAtCurrentSize
              .slice(0, more)
              .map((product: any, index: number) => {
                const productIndex = productListData.findIndex(p =>
                  p.Product?.sku === product.Product?.sku
                );

                return (
                  <Box
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      handleThumbnailClick(productIndex, event);
                    }}
                  >
                    <img
                      style={{
                        height: '50px',
                        width: '50px',
                        padding: '5px',
                        marginRight: '0',
                        border: selectedImagePreviewIndex === productIndex
                          ? '1px solid black'
                          : 'none',
                        objectFit: 'cover',
                      }}
                      src={product?.Product?.primaryImageUrl}
                      alt={`${product?.StockMaster?.colour}`}
                    />
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: '600',
                        textAlign: 'center',
                        mt: '4px',
                        color: selectedImagePreviewIndex === productIndex ? '#000' : '#666',
                      }}
                    >
                      {product.StockMaster?.colour}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
          {uniqueProducts?.length > (window.innerWidth > 600 ? 6 : 3) && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
              {more === (window.innerWidth > 600 ? 6 : 3) ? (
                <Button
                  variant="contained"
                  onClick={showMore}
                  disableElevation
                  sx={{
                    borderRadius: '0px',
                    height: '50px',
                    width: '50px',
                    background: '#000',
                    color: '#FFF',
                    textTransform: 'capitalize',
                  }}
                >
                  +{uniqueProducts.length - more}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={showLess}
                  disableElevation
                  sx={{
                    borderRadius: '0px',
                    height: '50px',
                    width: '50px',
                    background: '#000',
                    color: '#FFF',
                    textTransform: 'capitalize',
                  }}
                >
                  Less
                </Button>
              )}
            </Box>
          )}
        </Box>
        {isLogin && (
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: { xs: '10px', lg: '10px' },
            mt: 2,
            flexWrap: { xs: 'nowrap', lg: 'nowrap' },
            justifyContent: 'space-between',
          }}>
            <Button
              disableElevation
              sx={{
                width: { xs: '100%', lg: currentSelectedItem?.item_group_code === '005' ? '32%' : '48%' },
                height: '60px',
                borderRadius: 0,
              }}
              variant="contained"
              onClick={() => {
                const tradePrice = calculateTradePrice();
                console.log("TRADE PRICE", tradePrice);
                console.log('Adding to cart:', {
                  tradePrice: Number(tradePrice.toFixed(2)),
                  priceIncl: Number((tradePrice * 1.15).toFixed(2)),
                  isPromoProduct,
                  promoPrice: promoData?.promo_price_excl,
                  discountAmount: discountAmount?.discount_total,
                  currentItem: currentSelectedItem
                });
                const itemWithQuantity = {
                  ...currentSelectedItem,
                  price: Number(tradePrice.toFixed(2)),
                  priceIncl: Number((tradePrice * 1.15).toFixed(2)),
                  isPromo: isPromoProduct || !!currentPromoInfo,
                  discount_type: discountAmount?.discount_type,
                };
                addToCart(itemWithQuantity);
              }}
              disabled={
                productListData[currentIndex]?.StockMaster?.on_hand === 0 ||
                Number(categoryQuantity) > productListData[currentIndex]?.StockMaster?.on_hand ||
                Number(categoryQuantity) <= 0
              }
            >
              Add to cart
            </Button>

            <Button
              disableElevation
              sx={{
                width: { xs: '100%', lg: currentSelectedItem?.item_group_code === '005' ? '32%' : '48%' },
                height: '60px',
                borderRadius: 0,
                background: '#000',
                color: '#FFF',
                border: '1px solid #000',
                textTransform: 'none',
                fontWeight: 'bold',
                '&:hover': {
                  background: '#000',
                  color: '#FFF',
                  border: '1px solid #000',
                },
              }}
              onClick={() => {
                // Replace this download logic:
                const link = document.createElement('a');

                // Get all images (primary + additional)
                const primaryImage = productListData[currentIndex]?.Product?.primaryImageUrl;
                const additionalImages = productListData[currentIndex]?.Product?.additionalImages || [];
                const allImages = [
                  primaryImage,
                  ...additionalImages.map((img: any) => img.image_data)
                ].filter(Boolean);

                // Get the currently active image based on activeImageStep
                const currentImage = allImages[activeImageStep] || primaryImage;

                // Create descriptive filename
                const productSku = productListData[currentIndex]?.Product?.sku || 'product';
                const productColor = productListData[currentIndex]?.StockMaster?.colour || '';
                const sanitizedColor = productColor.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
                const imageNumber = activeImageStep + 1;

                link.href = currentImage;
                link.download = `hertex-${productSku}-${sanitizedColor}-image-${imageNumber}.jpg`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              DOWNLOAD IMAGE
            </Button>

            {currentSelectedItem?.item_group_code === '005' && (
              <Button
                disableElevation
                sx={{
                  width: { xs: '100%', lg: '32%' },
                  height: '60px',
                  borderRadius: 0,
                  background: '#000',
                  color: '#FFF',
                  border: '1px solid #000',
                  textTransform: 'none',
                  fontWeight: 'bold',
                  '&:hover': {
                    background: '#000',
                    color: '#FFF',
                    border: '1px solid #000',
                  },
                }}
                onClick={() => {
                  if (!currentSelectedItem) {
                    return;
                  }

                  const requestedQuantity = Number(categoryQuantity);
                  const currentStock = currentSelectedItem.stock;

                  // Basic validation
                  if (requestedQuantity <= 0) {
                    alert('Please enter a valid quantity greater than 0.');
                    return;
                  }

                  if (requestedQuantity > currentStock) {
                    alert(`Sorry, only ${currentStock} items are available in stock.`);
                    return;
                  }

                  const itemWithQuantity = {
                    ...currentSelectedItem,
                    quantity: requestedQuantity,
                  };

                  // Use the addToCuttingsCart function from context
                  addToCuttingsCart(itemWithQuantity);
                }}
                disabled={
                  !currentSelectedItem ||
                  productListData[currentIndex]?.StockMaster?.on_hand === 0 ||
                  Number(categoryQuantity) > (currentSelectedItem?.stock || 0) ||
                  Number(categoryQuantity) <= 0
                }
              >
                ADD TO CUTTINGS CART
              </Button>
            )}
          </Box>
        )}
      </Box>
      <StockRollModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={handleCloseModal}
        stockRollData={stockRollData}
        isSuccess={isSuccess}
        isError={isError}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ProductSection;